<template>
  <div :class="['editOrderBox', hideEditPage && 'hideEditPage']">
    <iHeader
      backType="blank"
      :headStyle="['msd', 'dsm'].includes($cookies.get('tenant')) ? { background: '#fff', color: '#13161B' } : { background: 'var(--themeColor)', color: '#fff' }"
      :backEvent="true"
      @back-event="editOrderGoBack()"
      :back="true"
    >
      <div slot="headText">
        <span class="head-txt">编辑订单</span>
      </div>
    </iHeader>

    <div class="contentBox">
      <div class="platformTips" v-if="crossevent_cspc">
        <div class="platformTips_top">
          注意事项
        </div>
        <div class="platformTips_con">
          请根据您的需求选择适合的车型，并注意您选择车型的价格差异。
        </div>
      </div>
      <div class="normalInputRow" @click="openComponent('carRule')">
        <MInput title="用车形式" noCancel="false" :must="true" disabled="true" v-model="params.carRule_txt" pla="请选择用车形式"></MInput>
      </div>
      <div v-if="params.carRule != 14">
        <div class="normalInputRow" @click="openUseDate('useDate')">
          <MInput title="出发时间" noCancel="false" :must="true" v-model="params.departure_time" disabled="true" pla="请选择出发时间"></MInput>
        </div>
      </div>

      <div v-if="params.carRule == 12">
        <div class="normalInputRow" @click="openComponent('useDays')">
          <MInput title="包车天数" noCancel="false" :must="true" disabled="true" v-model="params.useDays" pla="请选择包车天数"></MInput>
        </div>
      </div>
      <div class="normalInputRow departure" @click="opMap('start_name')">
        <div class="departure_label">
          <i class="greenBc"></i>
          <span>出发地</span>
          <span class="must">*</span>
        </div>
        <input type="text" v-model="params.start_name" class="departure_inner" placeholder="请选择出发地" disabled="true" />
      </div>
      <div class="normalInputRow departure" @click="opMap('end_name')" v-if="params.carRule != 12">
        <div class="departure_label">
          <i class="orangeBc"></i>
          <span>目的地</span>
          <span class="must">*</span>
        </div>
        <input type="text" v-model="params.end_name" class="departure_inner" placeholder="请选择目的地" disabled="true" />
      </div>
      <div v-if="params.carRule == '7' || params.carRule == '8'">
        <div class="normalInputRow">
          <MInput title="航班号" noCancel="false" :must="true" v-model="params.flight_num" pla="请输入航班号"></MInput>
        </div>
      </div>
      <div class="passengerBox" v-if="!['dsm', 'roche', 'novartis'].includes($cookies.get('tenant'))">
        <div class="normalInputRow ">
          <MInput title="乘车联系人" noCancel="false" :must="true" v-model="params.passenger.name" pla="请输入乘车人姓名"></MInput>
        </div>
        <div class="normalInputRow departure">
          <div class="departure_label">
            <span>乘车人联系电话</span>
            <span class="must">*</span>
          </div>
          <input type="number" pattern="[0-9]*" v-model="params.passenger_phone" class="departure_inner" placeholder="请输入乘车人手机号" />
        </div>
      </div>
      <div v-else-if="['dsm', 'roche', 'novartis'].includes($cookies.get('tenant'))">
        <div class="contact_box">
          <div class="passengerBox ">
            <div class="normalInputRow normalInputRowHalf">
              <MInput
                :title="['msd'].includes($cookies.get('tenant')) ? '乘车人' : '乘车联系人'"
                noCancel="false"
                :must="true"
                v-model="params.passenger.name"
                :disabled="['roche', 'novartis'].includes($cookies.get('tenant')) ? true : false"
                :pla="$cookies.get('tenant') == 'roche' ? '请选择乘车人姓名' : ['msd'].includes($cookies.get('tenant')) ? '请输入乘车人全名' : '请输入乘车人姓名'"
              ></MInput>
            </div>
          </div>
          <!-- 新ui升级 msd去掉通讯录需求 -->
          <!-- <div class="hcpBox" @click="showPhone" v-if="$cookies.get('tenant') == 'msd'">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconrenshishuju" />
            </svg>
            通讯录
          </div> -->
          <div class="hcpBox bighcpBox" @click="showPhone()" v-if="['dsm'].includes($cookies.get('tenant'))">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconrenshishuju" />
            </svg>
            常用联系人
          </div>
          <div class="hcpBox bighcpBox" @click="isAttenderDialog = true" v-else-if="$cookies.get('tenant') == 'roche'">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconrenshishuju" />
            </svg>
            库
          </div>
          <div class="hcpBox bighcpBox" @click="showPhone()" v-if="['novartis'].includes($cookies.get('tenant'))">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconrenshishuju" />
            </svg>
            参会人列表
          </div>
        </div>

        <div class="normalInputRow departure">
          <div class="departure_label">
            <span>{{ ['msd'].includes($cookies.get('tenant')) ? '乘车人电话' : '乘车联系人电话' }}</span>
            <span class="must">*</span>
          </div>
          <input
            type="number"
            pattern="[0-9]*"
            v-model="params.passenger_phone"
            :disabled="['novartis'].includes($cookies.get('tenant')) ? true : false"
            class="departure_inner"
            placeholder="请输入乘车人电话"
          />
        </div>
      </div>
      <div class="normalInputRow" v-if="['msd'].includes($cookies.get('tenant'))">
        <MInput title="乘车人医院" noCancel="false" :must="true" v-model="params.passenger_department" pla="请输入乘车人医院"></MInput>
      </div>
      <div class="normalInputRow departure" v-if="['pfizer'].includes($cookies.get('tenant'))">
        <div class="departure_label">
          <span>实际用车人数</span>
          <span class="must">*</span>
        </div>
        <input type="number" pattern="[0-9]*" v-model="params.qty" class="departure_inner" placeholder="请输实际用车人数" :disabled="params.ProposalStatus != 0 && params.isOrderFreeze" />
      </div>
      <div class="normalInputRow">
        <MInput
          :title="isDsmApproval ? '用车事由' : '备注'"
          :must="isDsmApproval ? true : false"
          noCancel="false"
          v-model="params.descr"
          :pla="isDsmApproval ? '请说明用车事由' : '请输入备注'"
        ></MInput>
      </div>
      <div v-if="['novartis'].includes($cookies.get('tenant')) && showFile" class="uploader_row">
        <div class="departure_label">
          <span>跨城用车审批图片:</span>
          <span class="must">*</span>
        </div>
        <viewer :images="approveFilesList" class="imgContainer">
          <template v-for="list in approveFilesList">
            <div :key="list.url">
              <img :src="list.url" alt />
              <Svgs color="#FF484D" class="paymentDelete" name="icon-guanbi3" @click.native="deleteInvoice(list, 'invoice')"></Svgs>
            </div>
          </template>
          <div class="uploadImg" @click="openBtnGroup('invoice')">
            <Svgs color="#FFFFFF" name="icon-tianjia3"></Svgs>
          </div>
        </viewer>
      </div>
      <div class="passengerList" v-if="['msd'].includes($cookies.get('tenant'))">
        <div class="titleBox">
          <span>添加其他乘车人</span>
          <div class="addPassenger" @click="goAddPassenger()">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tianjia3" />
            </svg>
            添加
          </div>
        </div>
        <div v-for="(passenger, pindex) in passengerList" :key="pindex" class="passager_item">
          <div class="passager_item_info">
            <p>{{ passenger.passengers }}</p>
            <span>{{ passenger.department }}</span>
            <span>{{ passenger.passenger_phone }}</span>
          </div>
          <div class="passager_item_edit">
            <div class="deletBtn" @click="removePassenger(pindex)">
              删除
            </div>
            <div class="editsBtn" @click="goAddPassenger(passenger)">
              编辑
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_btns" v-show="hidshow">
      <div class="editBtn" @click="GetPriceCoupon()">
        预估报价
      </div>
    </div>

    <Select val="value" :clickClose="true" :title="selecttTtle" @on-ok="chooseSelect" :selectList="selectList" ref="selectRef">
      <div slot="selectLeft" @click="choiseCity" v-if="selectOpen != 'carRule' && (params.carRule == 7 || params.carRule == 8)" style="width: 1rem">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icondaohang-tianchong" />
        </svg>
        {{ cityDictVal }}
      </div>
    </Select>
    <Popup v-model="showDataPicker" position="bottom" :style="{ height: '38%' }">
      <DatetimePicker
        ref="datePicker"
        :formatter="formatter"
        type="datetime"
        :filter="filter"
        :title="dateTitle"
        :min-date="startDate"
        :max-date="endDate"
        @confirm="chooseSelect"
        @cancel="showDataPicker = false"
      ></DatetimePicker>
    </Popup>
    <Map ref="mapPop" @close="close" />
    <BtnGroup ref="btnGroup" :btnList="btnList" />
    <SearchCityCar @on-ok="chooseCity" :cityList="cityList" v="2.0" ref="eventCity"></SearchCityCar>
    <chosePhonePerson ref="chosePhonePerson" @chosePPerson="chosePPerson"></chosePhonePerson>
    <choseAttender ref="choseAttender" @chosePPerson="chosePPerson"></choseAttender>
    <addPassenger @getPassengerItem="getPassengerItem" :passengerName="params.passenger.name" ref="addPassenger"></addPassenger>
    <div class="dialogbox" v-if="isAttenderDialog">
      <div class="maskBox"></div>
      <div class="brnBox">
        <span class="closeBtn" @click.stop="isAttenderDialog = false">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconclose" />
          </svg>
        </span>
        <div class="choseBtn">
          <a @click="choseAttender('QueryOftenAttender', '常用参会人')">常用参会人</a>
          <a @click="choseAttender('OutPagingByMarker', 'HCP(CRM)')">HCP（CRM客户）</a>
          <a @click="choseAttender(4, 'HCP(非CRM)')">HCP（非CRM客户）</a>
          <a @click="choseAttender(3), '外部非CRM'">外部非HCP客户</a>
          <a @click="choseAttender('InPagingByMarker', 'ROCHE员工(China)')">罗氏（中国）员工</a>
          <a @click="choseAttender(5, 'ROCHE员工(非China)')">罗氏（非中国）员工</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import filterEmoji from '@/views/controllers/filterEmoji.js';
import chosePhonePerson from '@/views/common/chosePhonePerson';
import choseAttender from '@/views/common/choseAttender';
import addPassenger from '@/views/common/addPassenger';
import vueFilter from '@/views/controllers/filter/vuefilter.js';
import { platformOrder as validator } from '@/views/controllers/carValidatorConfig';
import Map from '@/views/common/map/map';
import screenMixin from '@/views/controllers/getScreen.js';
import SearchCityCar from '@/views/common/SearchCityCar/SearchCityCar';
import { DatetimePicker, Popup } from 'vant';
import 'vant/lib/index.css';
import { UploadPlug } from 'smart-filer';
import BtnGroup from '@/views/common/btnGroup.vue';
import { Dialog } from 'vant';
const uploadInstance = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY
});
export default {
  mixins: [filterEmoji, screenMixin],
  data() {
    return {
      isDsmApproval: ['dsm'].includes(this.$cookies.get('tenant')) && this.SmartStorage.get('eventData').userEventType == 'approval',
      isAttenderDialog: false,
      hideEditPage: false,
      showDataPicker: false,
      endDate: '',
      dateTitle: '',
      startDate: '',
      cityList: [],
      passengerList: [],
      cityDictVal: this.SmartStorage.get('eventData')?.cityDictVal,
      selecttTtle: '',
      selectList: [],
      airportList: [],
      carTypeList: [],
      flagEidt: false,
      selectOpen: '',
      crossevent_cspc: false, //是否石药租户
      showFile: false, //山德士跨城用车审批图片展示
      fileList: [],
      params: {
        start_name: '',
        carRule: 0,
        passenger: {}
      },
      approveFilesList: [],
      btnList: [
        {
          txt: '拍照',
          callback: 'graph',
          icon: 'icon-paizhao'
        },
        {
          txt: '从相册选取',
          callback: 'album',
          icon: 'icon-tupian'
        }
      ],
      editdataForm: false //区分是新增还是编辑订单
    };
  },
  components: {
    Map,
    SearchCityCar,
    Popup,
    DatetimePicker,
    chosePhonePerson,
    choseAttender,
    addPassenger,
    // uploader,
    BtnGroup
  },
  beforeDestroy() {
    console.log('beforeDestroy==========================');
    this.params = null;
    this.cityList = null;
    this.map && this.map.destroy();
    this.SmartStorage.remove('editdataForm');
    this.SmartStorage.remove('priceCouponData');
    this.$root.$eventHub.$off('editOrderGoBack');
  },
  created() {
    this.setRouteListeners('editOrderGoBack');
    this.$root.$eventHub.$on('editOrderGoBack', data => {
      this.editOrderGoBack();
      this.$root.$eventHub.$off('editOrderGoBack'); //解绑当前监听，否则下轮监听还会持续叠加
    });
    if (['dsm'].includes(this.$cookies.get('tenant')) && this.SmartStorage.get('eventData').userEventType == 'approval') {
      this.getEmployee();
    }
    let tenant_id = this.SmartStorage.get('tenant_id') || '';
    switch (tenant_id) {
      case '207d3942-395e-11ed-90ea-863f87f6346f':
      case '7faf5393-8a70-11ec-89d2-6a0616dfdee4':
      case 'd1b74701-cd07-11ec-afb5-6a9b383694d2':
      case 'ee6ea2ae-f2c7-11ec-a826-be1305a58be3':
        this.crossevent_cspc = true;
        break;
      default:
        this.crossevent_cspc = false;
        break;
    }
    // let newDate = vueFilter.formatDate(new Date(), "yyyy-MM-dd hh:mm");
  },
  activated() {
    this.showFile = false;
    this.approveFilesList = [];
    if (!this.$route.meta.isBack && this.SmartStorage.get('editdataForm')) {
      console.log('编辑订单==', this.SmartStorage.get('editdataForm'), this.SmartStorage.get('editdataForm').editType);
      this.showDataPicker = false; //选择接送机时编辑订单后再次新增会自动弹出起飞时间框，故手动关闭
      let now = new Date();
      this.startDate = new Date(now.getTime() + 1000 * 60 * 30);
      this.endDate = new Date(new Date().setDate(new Date().getDate() + 2));
      this.GetCarService();
      this.getCity();
      this.geocoderGps();

      //加入页面初始化的逻辑
      let editdataForm = this.SmartStorage.get('editdataForm');
      if (editdataForm && editdataForm.itsItemData) {
        editdataForm.itsItemData.start_name = editdataForm.itsItemData.end_name ? editdataForm.itsItemData.start_name : '';
        this.orderList(editdataForm);
        (editdataForm.ItemId || editdataForm.itemId) && ['msd'].includes(this.$cookies.get('tenant')) && this.QueryPassenger(editdataForm);
        //山德士跨城用车审批邮件显示逻辑
        console.log('进入获取的this.params', this.params);
        if (['novartis'].includes(this.$cookies.get('tenant'))) {
          if (this.params.puCity && this.params.destCity) {
            if (this.params.destCity != this.params.puCity) {
              this.showFile = true;
              let editType = this.SmartStorage.get('editdataForm').editType;
              console.log('editType=====', editType);
              if (editType != 'copyOrder' && editType != 'rebackOrder') {
                this.editdataForm = true;
                let editItem = editdataForm.ItemId || editdataForm.itsItemData.itemId; //直接编辑订单和预估报价后返回订单获取的itemid不同
                this.getInvoiceFiles(editItem);
              } else {
                this.ItemId = this.generateUUID();
                this.editdataForm = false;
              }
            } else {
              this.showFile = false;
            }
          }
        }
      } else {
        console.log('新增====');
        this.editdataForm = false;
        this.ItemId = this.generateUUID();
        this.params = {
          start_name: '',
          carRule: 0,
          passenger: {}
        };
      }
    } else {
      this.dateTitle = '';
      this.params.flight_date = '';
    }
  },
  methods: {
    async QueryPassenger(data) {
      let params = {
        BusinessId: data.ItemId || data.itemId
      };
      await this.services.QueryPassenger(params).then(res => {
        if (res.success) {
          res.content.forEach((element, index) => {
            if (element.passengers == data.itsItemData.passenger.name && element.department == data.itsItemData.passenger_department) {
              res.content.splice(index, 1);
            }
            element.passengers = this.StringExchangeEmoji(element.passengers);
            element.department = this.StringExchangeEmoji(element.department);
          });
          this.passengerList = res.content;
        }
      });
    },
    removePassenger(index) {
      this.passengerList.splice(index, 1);
    },
    goAddPassenger(data) {
      this.flagEidt = data ? true : false;
      this.$refs.addPassenger.showPopup(data);
    },
    getPassengerItem(data) {
      !this.flagEidt && this.passengerList.push(data);
    },
    choseAttender(type, nameType) {
      this.$refs.choseAttender.showPopup({
        name: this.params.passenger.name,
        phoneNumber: this.params.passenger_phone,
        type: type,
        nameType: nameType
      });
      this.isAttenderDialog = false;
    },
    showPhone() {
      this.$refs.chosePhonePerson.showPopup({
        name: this.params.passenger.name,
        phoneNumber: this.params.passenger_phone
      });
      this.hideEditPage = true;
    },
    chosePPerson(data) {
      this.params.passenger.name = data.name;
      if (data.phoneNumber) {
        data.phoneNumber = data.phoneNumber.toString().replace(/-/g, '');
        this.params.passenger_phone = data.phoneNumber.toString().replace(/\s*/g, '');
      }
      this.$forceUpdate();
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 5 === 0);
      }
      return options;
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}`;
      } else if (type === 'month') {
        return `${val}`;
      }
      return val;
    },
    getCity() {
      let params = {
        keyCode: 'CPC-City'
      };
      let _this = this;
      this.services.queryDict(params).then(res => {
        if (res.success) {
          _this.cityList = res.content;
        }
      });
    },
    close(data) {
      this.initForm();
    },
    choiseCity() {
      this.$refs.eventCity.openPop();
    },
    chooseCity(e) {
      console.log('e====', e);
      if (e) {
        this.cityDictVal = e.val;
        this.geocoderGps(e);
      }
    },
    orderList(editdataForm) {
      let data = editdataForm.itsItemData;
      this.params = {
        passenger: data.passenger,
        departure_time: data.departure_time,
        carRule: data.carRule,
        carRule_txt: data.carRule_txt,
        useDays: data.useDays,
        end_name: data.end_name,
        start_name: data.start_name,
        flight_num: data.flight_num || '',
        flat: data.flat,
        start_address: data.start_address,
        flng: data.flng,
        puCity: data.puCity,
        end_address: data.end_address,
        tlat: data.tlat,
        tlng: data.tlng,
        destCity: data.destCity,
        passenger_phone: data.passenger_phone,
        clat: data.clat,
        clng: data.clng,
        flt: data.flt,
        puAddress: data.puAddress,
        destAddress: data.destAddress,
        end_city: data.end_city,
        start_city: data.start_city,
        airport_id: data.airport_id,
        airCode_txt: data.airCode_txt,
        airCode: data.airCode,
        end_type: data.end_type,
        start_type: data.start_type,
        estimateDistance: data.estimateDistance,
        passenger_department: data.passenger_department,
        descr: this.StringExchangeEmoji(data.descr),
        qty: data.qty
      };
      this.params.passenger.name = this.StringExchangeEmoji(this.params.passenger.name);
      this.params.itemId = editdataForm.itemId || editdataForm.itsItemData.itemId;
      if (data.carRule == 12) {
        this.params.end_address = '';
        this.params.destAddress = '';
        this.params.tlng = 0;
        this.params.tlat = 0;
        this.params.destCity = '';
        this.params.end_name = '';
      }
      this.$forceUpdate();
    },
    getEmployee() {
      let _this = this;
      this.services.getEmployee({}).then(res => {
        if (res.success) {
          _this.params.conditionData = [{ PositionName: res.content.positionName }];
        }
      });
    },
    editOrderGoBack() {
      this.removeRouteListeners('itemsGoBack');
      this.$store.commit('setValue', {
        key: 'homeWait',
        value: {
          date: new Date(),
          type: 'back'
        }
      });
      this.passengerList = [];
      this.SmartStorage.remove('editdataForm');
      this.$route.query.isFromeAll ? this.$router.push('/allCarOrder') : this.$router.push('/platformIndex');
    },
    async GetPriceCoupon() {
      if (
        (this.SmartStorage.get('checkCanNewItem').useStartDate && this.params.carRule == 14 && this.checkTime(vueFilter.formatDate(new Date(), 'yyyy-MM-dd'))) ||
        (this.SmartStorage.get('checkCanNewItem').useStartDate && this.params.carRule != 14 && this.params.departure_time && this.checkTime(this.params.departure_time))
      ) {
        this.$iMessageBox.render({
          btnType: 'single',
          singleTxt: '确定',
          messageTitle: '提示',
          messageTxt: '用车时间超出可用时间范围：' + this.SmartStorage.get('checkCanNewItem').useStartDate + '~' + this.SmartStorage.get('checkCanNewItem').useEndDate + '，不可用车'
        });
        return false;
      }

      this.$store.commit('SETpassengerList', this.passengerList.length > 0 ? this.passengerList : false);
      this.params.clat = this.SmartStorage.get('geolocation') ? this.SmartStorage.get('geolocation').lat : '40.044648';
      this.params.clng = this.SmartStorage.get('geolocation') ? this.SmartStorage.get('geolocation').lng : '116.321149';

      var validateResult = this.validate(this.params, validator);
      if (!validateResult) return;
      let reg = /^[0-9a-zA-Z]+$/;
      let pasengerReg = /^((?!教授|老师|医生|主任).)*$/;
      if (this.params.passenger.name && !pasengerReg.test(this.params.passenger.name)) {
        this.$iToast('乘车人姓名中不能包含“教授、老师、医生、主任”等，请录入乘车人全名');
        return false;
      }
      if (this.params.flight_num && !reg.test(this.params.flight_num)) {
        this.$iToast('请填写正确航班号');
        return false;
      }
      this.params.flt = this.params.flight_num;
      this.params.end_city = this.params.destCity;
      this.params.start_city = this.params.puCity;
      if (this.params.carRule == '14') {
        this.params.departure_time = null;
      }
      if (this.params.departure_time && new Date() >= new Date(this.params.departure_time.replace(/-/g, '/'))) {
        this.$iToast('请选择当前时间之后的时间');
        return false;
      }

      if (this.params.carRule == '7' && !this.params.flight_date) {
        this.dateTitle = '选择起飞日期';
        this.openUseDate('flyDate');
        return false;
      }
      this.params.passenger.name = this.EmojiExchangeString(this.params.passenger.name);
      console.log('this.params.descr', this.params.descr);
      if (this.isDsmApproval && (this.params.descr == undefined || this.params.descr == '')) {
        this.$iToast('请填写用车事由');
        return false;
      }
      this.params.descr = this.EmojiExchangeString(this.params.descr);
      this.params.IsOpenDiDiH5 = true;
      if (['dsm'].includes(this.$cookies.get('tenant')) && this.SmartStorage.get('eventData').userEventType == 'approval') {
        if (this.params.destCity != this.params.puCity) {
          this.$iToast('差旅不能跨城市用车，请在同一城市内选择出发地和目的地');
          return false;
        }
        this.params.miceType = '差旅单用车';
      }

      /**
       * 山德士下单用车卡控逻辑
       */

      //山德士跨城市用车逻辑
      if (['novartis'].includes(this.$cookies.get('tenant'))) {
        const eventData = this.SmartStorage.get('eventData');
        if (eventData.extData.isLargeMeeting) {
          if (this.showFile) {
            if (this.approveFilesList.length == 0) {
              this.$iToast('跨城用车请先上传审批图片');
              return false;
            } else {
              if (this.editdataForm) {
                // this.params.itemId = this.SmartStorage.get('editdataForm').ItemId;
                // this.params.itemId = ''
              } else {
                this.params.itemId = this.ItemId;
              }
              this.params.differentCity = 1;
            }
          } else {
            this.params.differentCity = 0;
          }
        } else {
          // 小会跨城用车逻辑
          if (this.showFile) {
            this.params.differentCity = 1;
          } else {
            this.params.differentCity = 0;
          }
        }

        if (eventData.extData.isLargeMeeting) {
          let isTrafficHub = /^((?!火车站|飞机场|高铁站).)*$/;
          //  大会 出发地和目的地都不是会议城市
          if (this.params.destCity != eventData.city && this.params.puCity != eventData.city) {
            console.log('非会议城市==');
            this.params.isMeetingCity = 0;
            if (!isTrafficHub.test(this.params.start_type) || !isTrafficHub.test(this.params.end_type)) {
              this.params.isTrafficHub = 1;
            } else {
              this.$iToast('大会用车必须在会议城市或者必须有一个非会议城市的交通枢纽');
              this.params.isTrafficHub = 0;
              return false;
            }
          } else {
            console.log('会议城市==');
            if (!isTrafficHub.test(this.params.start_type) || !isTrafficHub.test(this.params.end_type)) {
              this.params.isTrafficHub = 1;
            } else {
              this.params.isTrafficHub = 0;
            }
            this.params.isMeetingCity = 1;
          }
        } else {
          this.params.isTrafficHub = 0;
          if (this.params.destCity != eventData.city || this.params.puCity != eventData.city) {
            console.log('大会非会议城市==');
            this.params.isMeetingCity = 0;
            this.$iToast('小会不允许跨城用车');
            return false;
          } else {
            this.params.isMeetingCity = 1;
            console.log('小会会议城市==');
          }
        }
        this.params.proposalId = this.SmartStorage.get('proposalId'); //只有山德士在预估报价时才会传proposalId
      }
      console.log('报价this.params==', this.params);
      if (['pfizer'].includes(this.$cookies.get('tenant'))) {
        this.params.conditionData = [
          {
            TypeDictTxt: this.SmartStorage.get('eventData')?.typeDictTxt || this.SmartStorage.get('eventData')?.typeDictVal
          }
        ];
      }
      //只有山德士才走用车卡控，其他租户直接跳转获取报价
      if (['novartis'].includes(this.$cookies.get('tenant'))) {
        this.ExecuteDecider();
      } else {
        this.$router.push('/platformPriceCoupon');
        this.SmartStorage.set('editdataForm', { itsItemData: this.params });
        this.SmartStorage.set('priceCouponData', this.params);
        localStorage && localStorage.setItem('_AMap_AMap.IndoorMap', '');
      }
    },
    ExecuteDecider() {
      let params = {
        NodeCode: 'NCar-GetPriceCouponStream',
        ContextDataKeys: this.params
      };
      this.services.ExecuteDeciderDapr(params).then(res => {
        if (res.success) {
          this.$router.push('/platformPriceCoupon');
          this.SmartStorage.set('editdataForm', { itsItemData: this.params });
          this.SmartStorage.set('priceCouponData', this.params);
          localStorage && localStorage.setItem('_AMap_AMap.IndoorMap', '');
        }
      });
    },
    //打开高德h5
    async openGaode(itemId) {
      let params = {
        itemId: itemId
      };
      this.services.GetOrderUrl(params).then(res => {
        if (res.success) {
          let gaodearry = ['amap.com', 'smartmice', '#/publish'];
          this.gosafetyCenter('', res.content.url, this.ESWebappBack, gaodearry);
        }
      });
    },
    async ESWebappBack() {
      let params = {
        proposalId: this.SmartStorage.get('proposalId'),
        itemId: this.params.itemId
      };
      this.services.GetOnlineDetail(params).then(res => {
        if (res.success) {
          res.content.extOrderId && this.$router.push('/platformIndex');
        }
      });
    },
    GetCarService() {
      let _this = this;
      let params = {
        conditionData: [{ TypeDictTxt: this.SmartStorage.get('eventData')?.typeDictTxt || this.SmartStorage.get('eventData')?.typeDictVal || ''}]
      };
      this.services.GetCarService(params).then(res => {
        if (res.success) {
          res.content.forEach(element => {
            element.value = element.carType;
          });
          if (['dsm'].includes(this.$cookies.get('tenant')) && this.SmartStorage.get('eventData').userEventType == 'approval') {
            res.content.splice(
              res.content.findIndex(item => item.carRule == 12),
              1
            );
          }
          _this.carTypeList = res.content;
        }
      });
    },
    //更新表单信息
    initForm(choiseItem) {
      console.log('initForm==', this.$store.state.extdata);

      if (this.type == 'start_name' && this.$store.state.extdata.latitude) {
        this.params.start_type = this.$store.state.extdata.type;
        this.params.flat = this.$store.state.extdata.latitude;
        this.params.start_address = this.$store.state.extdata.address || this.$store.state.extdata.addressName;
        this.params.flng = this.$store.state.extdata.longitude;
        this.params.start_name = this.$store.state.extdata.addressName;
        this.params.puCity = this.$store.state.extdata.city;
        this.params.puAddress = this.$store.state.extdata.address + this.$store.state.extdata.addressName;
      } else if (this.type == 'end_name' && this.$store.state.extdata.latitude) {
        this.params.end_type = this.$store.state.extdata.type;
        this.params.end_address = this.$store.state.extdata.address;
        this.params.tlat = this.$store.state.extdata.latitude;
        this.params.tlng = this.$store.state.extdata.longitude;
        this.params.end_name = this.$store.state.extdata.addressName;
        this.params.destCity = this.$store.state.extdata.city;
        this.params.destAddress = this.$store.state.extdata.address + this.$store.state.extdata.addressName;
      } else if (choiseItem) {
        this.params.passenger.uci = choiseItem.uci;
        this.params.passenger.id = choiseItem.id;
        this.params.passenger.typeVal = choiseItem.typeVal;
        this.params.passenger.name = choiseItem.name || choiseItem.cName;
        this.params.passenger.chrisID = choiseItem.chrisID;
      }

      this.$forceUpdate();

      //山德士跨城用车审批邮件显示逻辑
      const eventData = this.SmartStorage.get('eventData');
      if (['novartis'].includes(this.$cookies.get('tenant'))) {
        if (this.params.puCity && this.params.destCity && eventData.extData.isLargeMeeting) {
          if (this.params.destCity != this.params.puCity) {
            this.showFile = true;
          } else {
            this.showFile = false;
          }
        }
      }
    },
    checkTime(datatime) {
      let data = this.SmartStorage.get('checkCanNewItem');
      if (
        new Date(datatime.replace(/-/g, '/').substr(0, 10)) > new Date(data.useEndDate.replace(/-/g, '/').substr(0, 10)) ||
        new Date(datatime.replace(/-/g, '/').substr(0, 10)) < new Date(data.useStartDate.replace(/-/g, '/').substr(0, 10))
      ) {
        return true;
      }
      return false;
    },
    chooseSelect(e) {
      let flag = false;
      let datatime = vueFilter.formatDate(e, 'yyyy-MM-dd hh:mm');

      switch (this.selectOpen) {
        case 'carRule':
          if (e.carRule == 14 && this.SmartStorage.get('checkCanNewItem').useStartDate && this.checkTime(vueFilter.formatDate(new Date(), 'yyyy-MM-dd'))) {
            this.$iMessageBox.render({
              btnType: 'single',
              singleTxt: '确定',
              messageTitle: '提示',
              messageTxt: '用车时间超出可用时间范围：' + this.SmartStorage.get('checkCanNewItem').useStartDate + '~' + this.SmartStorage.get('checkCanNewItem').useEndDate + '，不可用车'
            });
            return false;
          }
          this.params.end_name = e.carRule == 8 || e.carRule == 12 ? '' : this.params.end_name;
          this.params.start_name = e.carRule == 7 ? '' : this.params.start_name;
          (e.carRule == 8 || e.carRule == 7) && this.geocoderGps();
          if (e.carRule == 12) {
            this.params.end_address = '';
            this.params.destAddress = '';
            this.params.tlng = 0;
            this.params.tlat = 0;
            this.params.destCity = '';
          }
          this.$set(this.params, 'carRule', e.carRule);
          this.$set(this.params, 'carRule_txt', e.carType);

          break;
        case 'useDays':
          if (this.SmartStorage.get('checkCanNewItem').useStartDate && e.value != 0.5) {
            let now = new Date(this.params.departure_time.replace(/-/g, '/').substr(0, 10));
            let usedatatime = vueFilter.formatDate(now.setDate(now.getDate() + e.value), 'yyyy-MM-dd hh:mm');
            if (this.checkTime(usedatatime)) {
              this.$iMessageBox.render({
                btnType: 'single',
                singleTxt: '确定',
                messageTitle: '提示',
                messageTxt: '用车时间超出可用时间范围：' + this.SmartStorage.get('checkCanNewItem').useStartDate + '~' + this.SmartStorage.get('checkCanNewItem').useEndDate + '，不可用车'
              });
            }
          }
          this.$set(this.params, 'useDays', e.value);
          break;
        case 'useDate':
          this.showDataPicker = false;
          if (this.params.carRule == '12' && this.params.useDays && this.params.useDays != 0.5) {
            let useDate2 = new Date(datatime.replace(/-/g, '/').substr(0, 10));
            datatime = vueFilter.formatDate(useDate2.setDate(useDate2.getDate() + this.params.useDays), 'yyyy-MM-dd hh:mm');
          }
          if (this.SmartStorage.get('checkCanNewItem').useStartDate && this.checkTime(datatime)) {
            this.$iMessageBox.render({
              btnType: 'single',
              singleTxt: '确定',
              messageTitle: '提示',
              messageTxt: '用车时间超出可用时间范围：' + this.SmartStorage.get('checkCanNewItem').useStartDate + '~' + this.SmartStorage.get('checkCanNewItem').useEndDate + '，不可用车'
            });
            return false;
          }
          this.$set(this.params, 'departure_time', datatime);
          break;
        case 'flyDate':
          this.showDataPicker = false;
          console.log('flyDate--datatime', datatime);
          this.$set(this.params, 'flight_date', datatime);
          this.GetPriceCoupon();
          break;
        case 'Airport':
          if (this.params.carRule && this.params.carRule == 7) {
            this.params.start_name = e.airport_name;
            this.params.start_type = '交通设施服务;机场相关;飞机场';
            this.params.puCity = e.city_name;
            this.params.flat = e.lat;
            this.params.flng = e.lng;
            this.params.start_address = e.city_name;
            this.params.puAddress = this.params.start_name;
          } else {
            this.params.end_name = e.airport_name;
            this.params.end_type = '交通设施服务;机场相关;飞机场';
            this.params.destCity = e.city_name;
            this.params.tlat = e.lat;
            this.params.tlng = e.lng;
            this.params.end_address = e.city_name;
            this.params.destAddress = this.params.end_name;
          }
          this.params.airport_id = e.airport_id;
          this.params.airCode_txt = e.airport_name;
          this.params.airCode = e.code;
          this.$forceUpdate();
          break;
        default:
          break;
      }
    },
    onchangePicker(e) {
      console.log(e);
    },
    openComponent(type) {
      console.log('openComponentType===', type);
      this.selectOpen = type;
      switch (type) {
        case 'useDays':
          this.selectList = this.$cookies.get('tenant') == 'pfizer' ? [{ value: 0.5 }, { value: 1 }] : [{ value: 0.5 }, { value: 1 }, { value: 2 }, { value: 3 }];
          this.selecttTtle = '请选择天数';
          break;
        case 'carRule':
          this.selectList = this.carTypeList;
          this.selecttTtle = '请选择用车形式';
          break;
        case 'Airport':
          this.selectList = this.airportList;
          this.geocoderGps();
          this.selecttTtle = '请选择机场';
          break;
        default:
          break;
      }
      this.$refs.selectRef.openPop();
      // isSelectRef ? this.$refs.selectRef.openPop() : this.$refs[type].openPop();
      // this.type=='useDate'?this.dataVisible=true:(isSelectRef ? this.$refs.selectRef.openPop() : this.$refs[type].openPop());
    },
    openUseDate(type) {
      console.log('type===', type);
      this.showDataPicker = true;
      this.selectOpen = type;
    },
    async GetCityAirportsp(location) {
      let params = {
        lng: location.lng,
        lat: location.lat,
        city: this.cityDictVal,
        IsOpenDiDiH5: true
      };
      let _this = this;
      this.services.GetCityAirports(params).then(res => {
        if (res.success) {
          res.content.forEach(element => {
            element.value = element.airport_name;
          });
          _this.airportList = res.content;
          _this.selectList = res.content;

          // _this.$refs.eventCity.openPop();

          // this.changeVal = res.content[0];
        }
      });
    },
    GetCityAirports(location) {
      this.$iDelay(() => {
        this.GetCityAirportsp(location);
      }, 200);
    },
    //将城市转经纬度
    geocoderGps(e) {
      let _this = this;
      if (!e && ['dsm'].includes(this.$cookies.get('tenant')) && this.SmartStorage.get('eventData').userEventType == 'approval') {
        _this.cityDictVal = this.SmartStorage.get('eventData').cityDictVal.split(',')[0];
      }
      let AMap = (this.AMap = window.AMap);
      AMap.plugin('AMap.Geocoder', function() {
        var geocoders = new AMap.Geocoder({});
        geocoders.getLocation(_this.cityDictVal, function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            _this.GetCityAirports(result.geocodes[0].location);
          } else {
            _this.cityDictVal = '北京市';
            _this.geocoderGps();
          }
        });
      });
    },
    opMap(type, index) {
      if (this.params.carRule == 7 && type == 'start_name') {
        this.openComponent('Airport');
        // this.geocoderGps();
      } else if (this.params.carRule == 8 && type == 'end_name') {
        this.openComponent('Airport');
        // this.geocoderGps();
      } else {
        this.type = type;
        this.$refs.mapPop.open(
          type == 'start_name'
            ? {
                longitude: this.params.flng,
                latitude: this.params.flat,
                name: this.params.start_name,
                city: this.params.puCity
              }
            : {
                longitude: this.params.tlng,
                latitude: this.params.tlat,
                name: this.params.end_name,
                city: this.params.destCity
              }
        );
      }
    },
    async openBtnGroup() {
      // if (window.flutter_inappwebview) {
      //   let uploadFileData = {
      //     hideCamera: false,
      //     hideGallery: false,
      //     hidePdfAndOfd: true
      //   };
      //   this.iJsBridge.uploadFile(uploadFileData, this.mappingAsync);
      //   window.flutter_inappwebview.callHandler('App.removeLifeCycleJs');
      //   return;
      // }
      this.$refs.btnGroup.showPopup();
    },
    //拍照
    graph() {
      console.log('graph');
      let _this = this;
      uploadInstance.cameraFile({
        callback: data => {
          uploadInstance.addAsync({
            fileList: data,
            ossKey: '',
            bucketAlias: 'SmartEvent',
            callback: result => {
              _this.mappingAsync(result);
            }
          });
        }
      });
    },
    //相册选取
    album() {
      let _this = this;
      uploadInstance.localUpload({
        callback: data => {
          uploadInstance.addAsync({
            fileList: data,
            ossKey: '',
            bucketAlias: 'SmartEvent',
            callback: result => {
              _this.mappingAsync(result);
            }
          });
        }
      });
    },
    mappingAsync(result) {
      let Itemid;
      if (this.editdataForm) {
        console.log(111111111111);
        Itemid = this.SmartStorage.get('editdataForm').ItemId;
      } else {
        console.log(222222222222);
        Itemid = this.ItemId;
      }
      console.log('mappingAsyncitemid', Itemid);
      let mappingData = {
        catalog: '跨城用车',
        itemId: Itemid,
        processId: this.SmartStorage.get('proposalId'),
        relatedType: 'crossCity_approve'
      };
      uploadInstance.mappingAsync({
        fileKeys: [result[0].content.fileKey],
        mappingData: mappingData,
        callback: () => {
          console.log('mappingAsync', Itemid);
          this.getInvoiceFiles(Itemid);
        }
      });
    },
    getInvoiceFiles(ItemId) {
      uploadInstance.queryFile({
        filterData: {
          filterData: {
            catalog: '跨城用车',
            itemId: ItemId,
            processId: this.SmartStorage.get('proposalId'),
            relatedType: 'crossCity_approve'
          }
        },
        callback: data => {
          this.approveFilesList = data.content;
        }
      });
    },
    deleteInvoice(list, type) {
      console.log('list===', list);
      this.list = list;
      Dialog.confirm({
        title: '提示',
        message: '确定删除图片？'
      }).then(() => {
        this.remove();
      });
    },
    remove() {
      let Itemid;
      if (this.editdataForm) {
        Itemid = this.SmartStorage.get('editdataForm').ItemId;
      } else {
        Itemid = this.ItemId;
      }
      uploadInstance.deleteAsync({
        fileItem: {
          fileKey: this.list.fileKey
        },
        bucketAlias: 'SmartEvent',
        callback: () => {
          this.getInvoiceFiles(Itemid);
        }
      });
    },
    //随机itemId
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
  }
};
</script>
<style lang="scss">
@import '@/assets/platform/index.scss';
.imgContainer div {
  float: left;
  position: relative;
  margin: 0 0.2rem 0.2rem 0;
}
.uploadImg {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.04rem;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadImg .icon {
  width: 0.4rem !important;
  height: 0.4rem !important;
}
.imgContainer div img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.04rem;
}
.paymentDelete {
  position: absolute;
  right: -0.1rem;
  top: -0.05rem;
  width: 0.25rem !important;
  height: 0.25rem !important;
}
</style>
